import { useParams } from 'react-router-dom';

import { getFriendlyChatName } from '../../libs/hooks/useChat';
import { useAppSelector, useConversationsListSelector } from '../../redux/app/hooks';
import { timestampToDateString } from '../utils/TextUtils';
import ChatListItem from './ChatListItem';
import { RootState } from '../../redux/app/store';
import { getIsShared } from '../assistant-hub/helper';

import chatListStyles from './chatlist.module.scss';

export const ChatListSection: React.FC = () => {
    const conversationsList = useConversationsListSelector();
    const { selectedId } = useParams();

    const allAssistants = useAppSelector((state: RootState) => state.assistants.assistants);
    const sharedAssistantsIds = Object.values(allAssistants)
        .filter(getIsShared)
        .map((a) => a.id);

    return conversationsList.length > 0 ? (
        <div aria-label="Chat history list" className={chatListStyles['chat-list-section']}>
            {conversationsList.map((c) => (
                <ChatListItem
                    aria-label={`Open ${c.title} chat`}
                    id={c.id}
                    key={c.id}
                    isSelected={c.id === selectedId}
                    header={getFriendlyChatName(c.title)}
                    time={timestampToDateString(c.lastMessageTimestamp)}
                    preview={c.lastMessage}
                    isAssistant={c.isAssistant as boolean}
                    createdOn={c.createdOn}
                    createdFromSharedAssistant={sharedAssistantsIds.includes(c.assistantId ?? '')}
                />
            ))}
        </div>
    ) : null;
};
