import { Toast, ToastIntent, ToastTitle, Toaster, useId, useToastController } from '@fluentui/react-components';
import { FC, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { removeAlert } from '../../redux/features/app/appSlice';

const toastLimit = 5; // Limit the number of toasts that can be displayed at once

const Alerts: FC = () => {
    const toasterId = useId('toaster');
    const dispatch = useAppDispatch();
    const alerts = useAppSelector((state: RootState) => state.app.alerts);
    const { dispatchToast } = useToastController(toasterId);

    useEffect(() => {
        if (alerts.length) {
            alerts.forEach(({ message, type, isToast }, index) => {
                if (isToast) {
                    notify(message, index, type);
                }
            });
        }
    }, [alerts]);

    const notify = (message: string, index: number, type?: ToastIntent) => {
        dispatchToast(
            <Toast>
                <ToastTitle style={{ fontFamily: 'IBM Plex Sans', fontWeight: 'normal' }}>{message}</ToastTitle>
            </Toast>,
            { pauseOnHover: true, intent: type ?? 'info', onStatusChange: () => dispatch(removeAlert(index)) },
        );
    };

    return <Toaster toasterId={toasterId} limit={toastLimit} />;
};

export default Alerts;
