import React from 'react';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

import classes from './AbsentAssistants.module.scss';

const AbsentAssistants: React.FC<{
    shared?: boolean;
    children?: React.ReactNode;
}> = ({ shared, children }) => {
    const [header, description] = shared
        ? ['No shared assistant for now', 'Itera’s shared assistants for everyday use will appear here.']
        : [
              'Create your first assistant',
              'Let it take care of the chores that steal your time. You can manage your assistants here anytime.',
          ];

    return (
        <div className={classes.container}>
            <div className={classes['icon-wrapper']}>
                <img src="/AI_green.svg" className={classes.icon} alt="Robot face with green underline" />
                {shared && <SlIcon name="share" className={classes['share-icon']}></SlIcon>}
            </div>
            <div>
                <h2 className={classes.header}>{header}</h2>
                <p className={classes.description}>{description} </p>
            </div>
            {children}
        </div>
    );
};

export default AbsentAssistants;
