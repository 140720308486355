export interface GPTDescription {
    title: string;
    serviceId: string;
    summaryItems: string[];
    listItems: string[];
}

export type GPTServiceId = 'gpt4oMini' | 'gpt4o';

export const gpt4oMiniDescription: GPTDescription = {
    title: 'GPT-4o mini',
    serviceId: 'gpt4oMini',
    summaryItems: ['Fast and Efficient', 'Your Go-To AI'],
    listItems: [
        'GPT-4o mini: Versatile for a broad range of uses.',
        'Common Queries: Ideal for everyday questions and tasks.',
        'Efficient and Cost-Effective: Great for regular assistance.',
        'Limitation: Less depth in complex or nuanced topics.',
    ],
};

export const gpt4oDescription: GPTDescription = {
    title: 'GPT-4o',
    serviceId: 'gpt4o',
    summaryItems: ['Advanced and In-Depth', 'For Specialized Needs'],
    listItems: [
        'Use GPT-4o: Best for advanced, demanding tasks.',
        'Detailed and Context-Aware: Masters uanced interactions.',
        'Consideration: More resource-intensive, can be slower.',
        'Cost Factor: 15x more expensive, use with caution.',
    ],
};

export const allModels = [gpt4oMiniDescription, gpt4oDescription].reduce(
    (result, { serviceId, title }) => ({
        ...result,
        [serviceId]: title,
    }),
    {},
);
// allModels = {
//     "gpt4oMini": "GPT-4o mini",
//     "gpt4o": "GPT-4o"
// }

export const cardArray: GPTDescription[] = [gpt4oMiniDescription, gpt4oDescription];

export const creativityOptions = [
    {
        temp: 0.1,
        value: 'precise',
        title: 'More precise',
        description: 'For factual tasks',
    },
    {
        temp: 0.5,
        value: 'balanced',
        title: 'More balanced',
        description: 'For general use',
    },
    {
        temp: 0.9,
        value: 'creative',
        title: 'More creative',
        description: 'For innovative tasks',
    },
];
