import { IAssistant } from '../../libs/models/Assistants';

export const getIsShared = (a: IAssistant) => a?.assistantScope == 0;

const ONE_HOUR = 60 * 60 * 1000; //milliseconds
const RECENTLY = 24 * ONE_HOUR;

export const isRecently = (dateString: any) => {
    try {
        const timeBetween = Date.now() - new Date(String(dateString)).getTime();
        return timeBetween < RECENTLY;
    } catch (erorr) {
        return false;
    }
};

export const isEditedAfterShare = (lastEditedOn: any, sharedOn: any) => {
    try {
        return new Date(String(lastEditedOn)).getTime() > new Date(String(sharedOn)).getTime();
    } catch (erorr) {
        return false;
    }
};

export const formatDate = (dateString: any) => {
    try {
        const d = new Date(String(dateString));
        return (
            d.toLocaleDateString('nb-NO', { year: '2-digit', month: '2-digit', day: '2-digit' }) +
            ', ' +
            d.toLocaleTimeString('nb-NO', { hour: '2-digit', minute: '2-digit' })
        );
    } catch (erorr) {
        return String(dateString);
    }
};
