import { InteractionStatus, IPublicClientApplication } from '@azure/msal-browser';
import * as speechSdk from 'microsoft-cognitiveservices-speech-sdk';
import { Recognizer, SessionEventArgs } from 'microsoft-cognitiveservices-speech-sdk';
import { useEffect, useRef, useState } from 'react';
import { AuthHelper } from '../../../libs/auth/AuthHelper';
import { AlertType } from '../../../libs/models/AlertType';
import { SpeechService } from '../../../libs/services/SpeechService';
import { useAppDispatch } from '../../../redux/app/hooks';
import { addAlert } from '../../../redux/features/app/appSlice';
import { getErrorDetails } from '../../utils/TextUtils';

export const SpeechRecognizer = (
    onRecognizingChange: ((value: string) => void) | undefined = undefined,
    onRecognizedChange: (value: string) => void,
    instance: IPublicClientApplication,
    inProgress: InteractionStatus,
    startText = '',
) => {
    const dispatch = useAppDispatch();
    const isInitializingRef = useRef(false);
    const [recognizer, setRecognizer] = useState<speechSdk.SpeechRecognizer>();
    const [isListening, setIsListening] = useState(false);
    const [recognizedText, setRecognizedText] = useState('');
    const [recognizingText, setRecognizingText] = useState('');
    const recognizedTextRef = useRef(startText);
    const isRecognizingText = useRef(false);

    const joinSentences = (a: string, b: string): string => {
        const normalizedA = a.trim();
        const normalizedB = b.trim();
        if (normalizedA == '') {
            return normalizedB;
        }
        if (normalizedB == '') {
            return normalizedA;
        }
        return `${normalizedA} ${updateFirstLetterCase(normalizedB, normalizedA)}`;
    };

    const clearInputs = () => {
        setRecognizedText('');
        setRecognizingText('');
    };

    const updateFirstLetterCase = (normalizedText: string, prevNormalizedText = '') => {
        if (!normalizedText) return normalizedText; // Handle empty or undefined texts

        const needsCapitalization =
            prevNormalizedText == '' || /[.!?]$/.test(prevNormalizedText) || /^[Ii][\'’´ ]/.test(normalizedText);

        if (needsCapitalization) {
            return normalizedText.charAt(0).toUpperCase() + normalizedText.slice(1);
        } else {
            return normalizedText.charAt(0).toLowerCase() + normalizedText.slice(1);
        }
    };

    const setText = (value: string) => {
        setRecognizedText('');
        recognizedTextRef.current = value;
    };

    const handleSessionStart = (_sender: Recognizer, _event: SessionEventArgs): void => {
        setRecognizedText(startText);
        setIsListening(true);
    };

    const handleRecognizing = (_sender: Recognizer, e: speechSdk.SpeechRecognitionEventArgs): void => {
        if (e.result.reason === speechSdk.ResultReason.RecognizingSpeech && e.result.text.length > 0) {
            isRecognizingText.current = true;
            setRecognizingText(joinSentences(recognizedTextRef.current, e.result.text));
        }
    };

    const handleRecognized = (_sender: Recognizer, e: speechSdk.SpeechRecognitionEventArgs): void => {
        if (e.result.reason === speechSdk.ResultReason.RecognizedSpeech && e.result.text.length > 0) {
            recognizedTextRef.current = joinSentences(recognizedTextRef.current, e.result.text);
            setRecognizedText(recognizedTextRef.current);
            setRecognizingText('');
            isRecognizingText.current = false;
        }
    };

    const handleSessionStop = (_sender: Recognizer, _event: SessionEventArgs): void => {
        isRecognizingText.current = false;
        setIsListening(false);
    };

    useEffect(() => {
        async function initializeSpeechRecognizer() {
            try {
                const speechService = SpeechService.getInstance();
                const response = await speechService.getSpeechTokenAsync(
                    await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                );

                if (response.isSuccess) {
                    const newRecognizer = speechService.getSpeechRecognizerAsyncWithValidKey(response);
                    if (newRecognizer) {
                        newRecognizer.sessionStarted = handleSessionStart;
                        newRecognizer.recognizing = handleRecognizing;
                        newRecognizer.recognized = handleRecognized;
                        newRecognizer.sessionStopped = handleSessionStop;
                        setRecognizer(newRecognizer);
                    }
                }
            } catch (e) {
                const errorDetails = getErrorDetails(e);
                const errorMessage = `Unable to initialize speech recognizer. Details: ${errorDetails}`;
                dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
            } finally {
                isInitializingRef.current = false;
            }
        }

        if (!recognizer && !isInitializingRef.current) {
            isInitializingRef.current = true;
            void initializeSpeechRecognizer();
        }
    }, []);

    useEffect(() => {
        if (isListening && recognizedText.length > 0) {
            onRecognizedChange(recognizedText);
        }
    }, [recognizedText]);

    useEffect(() => {
        if (isListening && recognizingText.length > 0) {
            onRecognizingChange && onRecognizingChange(recognizingText);
        }
    }, [recognizingText]);

    const handleSpeech = () => {
        if (isListening) {
            stopListening();
        } else {
            startListening();
        }
    };

    const stopListening = () => {
        if (!recognizer || !isListening) return;
        try {
            recognizer.stopContinuousRecognitionAsync();
        } catch (error) {
            console.error('Error stopping speech recognition:', error);
            // Handle error appropriately
        } finally {
            setIsListening(false);
        }
    };

    const startListening = (startText = '') => {
        if (!recognizer) {
            console.error('Speech recognizer not initialized!');
            return;
        }
        try {
            if (startText) {
                setRecognizedText(startText);
            } else {
                clearInputs();
            }
            recognizer.startContinuousRecognitionAsync();
            setIsListening(true);
        } catch (error) {
            console.error('Error starting speech recognition:', error);
            // Handle error appropriately, e.g., display an error message
        }
    };

    return {
        recognizer,
        setText,
        handleSpeech,
        startListening,
        stopListening,
        isListening,
        isRecognizingText,
        recognizingText,
        recognizedText,
    };
};
