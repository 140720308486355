import { Dispatch } from 'react';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { setPreferences } from './appSlice';

export interface IPreferences {
    showSideBar: boolean;
    createdByMe: boolean;
    createdByOthers: boolean;
    sharedAssistantFilter: string;
    pinnedAssistants: string[];
    activeAssistants: string[];
}

export interface IPreferencesOptional {
    showSideBar?: boolean;
    createdByMe?: boolean;
    createdByOthers?: boolean;
    sharedAssistantFilter?: string;
    pinnedAssistants?: string[];
    activeAssistants?: string[];
}

class Preferences {
    private static instance: Preferences;
    private storageKey: string;
    private defaultSettings: IPreferences;
    private static dispatch: ThunkDispatch<RootState, undefined, AnyAction> & Dispatch<AnyAction>;

    private constructor(storageKey = 'PREFERENCES') {
        this.storageKey = storageKey;
        this.defaultSettings = {
            showSideBar: true,
            createdByMe: true,
            createdByOthers: true,
            sharedAssistantFilter: '',
            pinnedAssistants: [] as string[],
            activeAssistants: [] as string[],
        };

        if (!localStorage.getItem(this.storageKey)) {
            this.save(this.defaultSettings);
        }
    }

    public static getInstance() {
        if (!Preferences.instance) {
            Preferences.instance = new Preferences();
        }
        return Preferences.instance;
    }

    public static setDispatch(dispatch: ThunkDispatch<RootState, undefined, AnyAction> & Dispatch<AnyAction>) {
        Preferences.dispatch = dispatch;
    }

    public getDefault(): IPreferences {
        return this.defaultSettings;
    }

    private save(preferences: IPreferences): void {
        try {
            localStorage.setItem(this.storageKey, JSON.stringify(preferences));
        } catch (error) {
            console.error('Failed to save preferences:', error);
        }
    }

    public get(): IPreferences {
        try {
            const data = localStorage.getItem(this.storageKey);
            return data ? (JSON.parse(data) as IPreferences) : { ...this.defaultSettings };
        } catch (error) {
            console.error('Failed to read preferences:', error);
            return { ...this.defaultSettings };
        }
    }

    public set(update: IPreferencesOptional): void {
        const preferences = this.get();
        this.save({ ...preferences, ...update });
    }

    public isPinned(assistantId: string): boolean {
        const { pinnedAssistants } = this.get();
        return pinnedAssistants.includes(assistantId);
    }
    public toggleAssistantPin(assistantId: string): void {
        if (!assistantId || !Preferences.dispatch || !this.isActive(assistantId)) return;
        const { pinnedAssistants } = this.get();
        Preferences.dispatch(
            setPreferences({
                pinnedAssistants: this.isPinned(assistantId)
                    ? pinnedAssistants.filter((id) => id !== assistantId)
                    : [...pinnedAssistants, assistantId],
            }),
        );
    }
    public isActive(assistantId: string): boolean {
        const { activeAssistants } = this.get();
        return activeAssistants.includes(assistantId);
    }
    public toggleAssistantActiveness(assistantId: string): void {
        if (!assistantId || !Preferences.dispatch) return;
        const { activeAssistants, pinnedAssistants } = this.get();
        const preferencesUpdate: IPreferencesOptional = {
            activeAssistants: this.isActive(assistantId)
                ? activeAssistants.filter((id) => id !== assistantId)
                : [...activeAssistants, assistantId],
        };
        // unpin assistant on deactivation
        if (this.isPinned(assistantId) && this.isActive(assistantId)) {
            preferencesUpdate.pinnedAssistants = pinnedAssistants.filter((id) => id !== assistantId);
        }

        Preferences.dispatch(setPreferences(preferencesUpdate));
    }

    public excludeDeletedAssistants(loadedAssistantsIds: string[]): void {
        if (!loadedAssistantsIds.length) return;
        const { activeAssistants, pinnedAssistants } = this.get();
        const assistantExist = (id: string) => loadedAssistantsIds.includes(id);
        this.set({
            activeAssistants: activeAssistants.filter(assistantExist),
            pinnedAssistants: pinnedAssistants.filter(assistantExist),
        });
    }

    public excludeDeletedAssistant(deletedAssistantId: string): void {
        const { activeAssistants, pinnedAssistants } = this.get();
        const isNotDeleted = (id: string) => deletedAssistantId !== id;
        const preferencesUpdate = {
            activeAssistants: activeAssistants.filter(isNotDeleted),
            pinnedAssistants: pinnedAssistants.filter(isNotDeleted),
        };
        Preferences.dispatch(setPreferences(preferencesUpdate));
    }

    public setShowSidebar(newValue: boolean): void {
        Preferences.dispatch(setPreferences({ showSideBar: newValue }));
    }
    public toggleShowSidebar(): void {
        const { showSideBar } = this.get();
        Preferences.dispatch(setPreferences({ showSideBar: !showSideBar }));
    }
}

export default Preferences;
