import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useMsal } from '@azure/msal-react';
import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlTextarea, { SlInputEvent } from '@shoelace-style/shoelace/dist/react/textarea';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import SlSpinner from '@shoelace-style/shoelace/dist/react/spinner';

import { useChat } from '../../../libs/hooks';
import { useGoPage } from '../../../libs/hooks/useRouterNavigate';
import { useAppDispatch, useAppSelector, useConversationsListSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { setNavigation } from '../../../redux/features/navigation/navigationSlice';
import Preferences from '../../../redux/features/app/Preferences';
import { SpeechRecognizer } from '../../chat/controls/SpeechRecognizer';
import OnboardingModal from '../../modal/OnboardingModal';
import { onboardingBodies, onboardingImages, whoAmIBodies, whoAmIImages } from '../../modal/OnboardingModalData';
import { ViewStates } from '../ViewStates';
import { AssistantsList } from './AssistantsList';
import { ChatsList } from './ChatsList';
import { DetailsDropdown } from './DetailsDropdown';
import { Divider } from './Divider';
import UpdateDialog from './UpdateDialog';
import { EStatus } from '../../../redux/features/conversations/ConversationsState';

import startPageClasses from './StartPage.module.scss';
import toolTipStyles from '../../global/tooltip.module.scss';
import inputControlClasses from '../../chat/controls/InputControlButtons.module.scss';

export const StartPage: React.FC = () => {
    const startPageRef = React.useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const { goStartPage } = useGoPage();
    const { goCreateAssistant, goManageAssistant } = useGoPage();
    const { navigation } = useAppSelector((state: RootState) => state.navigation);
    const { status } = useAppSelector((state: RootState) => state.assistants);
    const conversationsList = useConversationsListSelector();

    // The following code with resizeObserver is used to protect against ERROR "ResizeObserver loop completed with undelivered notifications."
    // This error appears if Shoelace tries to automatically adjust the size of the SlTextarea (with resize='auto').
    // So when we resize start page, we temporarily set SlTextarea resize to 'none'
    const [resize, setResize] = useState<'auto' | 'none' | 'vertical' | undefined>();
    useEffect(() => {
        let resizeTimer: NodeJS.Timeout;
        const resizeObserver = new ResizeObserver(() => {
            setResize('none');
            resizeTimer = setTimeout(() => {
                setResize('auto');
            }, 500);
        });
        startPageRef.current && resizeObserver.observe(startPageRef.current);

        return () => {
            startPageRef.current && resizeObserver.unobserve(startPageRef.current);
            clearTimeout(resizeTimer);
        };
    }, []);
    // end of protection from ERROR "ResizeObserver loop completed with undelivered notifications."

    const preferencesInstance = Preferences.getInstance();
    const { pinnedAssistants } = preferencesInstance.get();

    const enableWhoAmI = [ViewStates.StartIntro, ViewStates.StartWhoAmI].includes(navigation);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    // Check if onboarding is required.
    const [cookies, setCookie] = useCookies(['COMPLETED_ONBOARDING']);
    if (!cookies.COMPLETED_ONBOARDING && !isDialogOpen) {
        setIsDialogOpen(true);
    }

    const goToStartPage = () => {
        dispatch(setNavigation(ViewStates.Start));
        goStartPage();
    };

    const closeDialog = () => {
        setCookie('COMPLETED_ONBOARDING', true, { maxAge: 60 * 60 * 24 * 365 * 10, path: '/' });
        preferencesInstance.toggleShowSidebar();
        setIsDialogOpen(false);
    };

    const chat = useChat('StartPage');
    const [value, setValue] = useState('');
    const [creatingNewChat, setCreatingNewChat] = useState(false);
    const { instance, inProgress } = useMsal();

    const [isWriting, setIsWriting] = useState(false);
    const [model, setModel] = useState('GPT-4o mini');
    const [creativity, setCreativity] = useState('Balanced');

    const handleCreativityChange = (event: CustomEvent) => {
        setCreativity((event.target as HTMLInputElement).value);
    };

    const handleModelChange = (event: CustomEvent) => {
        setModel((event.target as HTMLInputElement).value);
    };

    // ---- When the user sends a message
    const handleNewChat = () => {
        const serviceId = model === 'GPT-4o mini' ? 'gpt4oMini' : 'gpt4o';
        const creativityValue = creativity === 'Balanced' ? 0.5 : creativity === 'Precise' ? 0.1 : 0.9;
        // Ensure that the speech recognizer is stopped before creating a new chat
        speechRecognizer.isListening && speechRecognizer.stopListening();
        setCreatingNewChat(true);
        void chat.createChatThenSetToFalse(setCreatingNewChat, '', '', serviceId, creativityValue, false, value);
    };

    const onSpeechRecognizedInput = (value: string) => {
        onInputChange(value);
    };

    // ---- When the user types in the chat input field
    const onChange = (event: SlInputEvent) => {
        const target = (event.target ?? event.detail) as HTMLInputElement;
        speechRecognizer.setText(target.value);
        onInputChange(target.value);
    };

    const onInputChange = (value: string) => {
        setValue(value);
        setIsWriting(value.length > 0);
    };

    const speechRecognizer = SpeechRecognizer(undefined, onSpeechRecognizedInput, instance, inProgress, value);

    const ChatSettings: React.FC = () => (
        <div className={startPageClasses['chat-settings']}>
            <DetailsDropdown
                creativity={creativity}
                model={model}
                handleCreativityChange={handleCreativityChange}
                handleModelChange={handleModelChange}
            />
        </div>
    );

    const CreateAssistantButton: React.FC = () => (
        <SlButton onClick={goCreateAssistant} className={startPageClasses['create-assistant-button']}>
            Create an assistant
        </SlButton>
    );

    return (
        <div ref={startPageRef} className={startPageClasses['start-page-section']}>
            <div className={startPageClasses['start-page-elipse-upper-corner-bg']} />
            <div className={startPageClasses['start-page-elipse-lower-corner-bg']} />

            <div className={startPageClasses.scroll}>
                <div className={startPageClasses['start-page-body']}>
                    <div className={startPageClasses.invitation}>
                        <span className={startPageClasses['jade-text']}>Hey,</span>
                        <span>&nbsp;let&apos;s do some stuff.</span>
                    </div>
                    <div className={startPageClasses['start-chat-section']}>
                        <div className={startPageClasses.relative}>
                            <div className={inputControlClasses['input-controls-container']}>
                                {speechRecognizer.recognizer && (
                                    <SlTooltip
                                        content={(speechRecognizer.isListening ? 'Stop' : 'Start') + ' speech to text'}
                                        className={toolTipStyles['sapience-tooltip']}
                                    >
                                        <SlButton
                                            aria-label="Speech to text"
                                            variant="text"
                                            disabled={creatingNewChat}
                                            onClick={speechRecognizer.handleSpeech}
                                            className={
                                                inputControlClasses[
                                                    speechRecognizer.isListening
                                                        ? 'text-to-speech-button'
                                                        : 'input-control-button'
                                                ]
                                            }
                                        >
                                            <Icon
                                                icon={
                                                    speechRecognizer.isRecognizingText.current
                                                        ? 'lets-icons:stop-light'
                                                        : 'lets-icons:mic-light'
                                                }
                                                width="24px"
                                                height="24px"
                                            />
                                        </SlButton>
                                    </SlTooltip>
                                )}
                                {/* ---- Disable text to speech if input field is not empty ---- */}
                                <SlTooltip content="Send your message" className={toolTipStyles['sapience-tooltip']}>
                                    <SlButton
                                        title="Submit"
                                        aria-label="Submit message"
                                        variant="text"
                                        disabled={!isWriting}
                                        onClick={handleNewChat}
                                        className={inputControlClasses['send-button']}
                                    >
                                        <Icon icon="lets-icons:send-hor-light" width="24px" height="24px" />
                                    </SlButton>
                                </SlTooltip>
                            </div>
                            <SlTextarea
                                title="Chat input"
                                aria-label="Chat input field. Click enter to submit input."
                                id="chat-input"
                                placeholder={
                                    speechRecognizer.recognizingText.length == 0
                                        ? 'I am ready for your questions, instructions and requests.'
                                        : ''
                                }
                                label="Start a new chat"
                                value={
                                    speechRecognizer.isRecognizingText.current
                                        ? speechRecognizer.recognizingText
                                        : value
                                }
                                resize={resize}
                                disabled={speechRecognizer.isRecognizingText.current}
                                rows={1}
                                onFocus={() => {
                                    // update the locally stored value to the current value
                                    const chatInput = document.getElementById('chat-input');
                                    if (chatInput) {
                                        setValue((chatInput as HTMLTextAreaElement).value);
                                    }
                                }}
                                onSlInput={onChange}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' && !event.shiftKey && !creatingNewChat) {
                                        event.preventDefault();
                                        handleNewChat();
                                    }
                                }}
                                className={startPageClasses['chat-input']}
                            />
                        </div>
                    </div>
                    <ChatSettings />
                    <CreateAssistantButton />

                    <div className={startPageClasses['hidden-for-mobile']}>
                        <Divider title="Chat with your assistants" />
                        {(status === EStatus.Loading && (
                            <div className={startPageClasses.spinner}>
                                <SlSpinner />
                            </div>
                        )) ||
                            (pinnedAssistants.length > 0 && (
                                <>
                                    <AssistantsList />
                                    <CreateAssistantButton />
                                </>
                            )) || (
                                <div className={startPageClasses['empty-state-container']}>
                                    <div className={startPageClasses['empty-state-text-container']}>
                                        <span>Your pinned assistants will appear here.</span>
                                        <span>If you want to pin assistant go to &quot;Manage assistants&quot;</span>
                                    </div>
                                    <SlButton
                                        className={startPageClasses['manage-assistants-button']}
                                        onClick={goManageAssistant}
                                    >
                                        Manage assistants
                                    </SlButton>
                                </div>
                            )}

                        <Divider title="Recent chats" />
                        {conversationsList.length > 0 ? (
                            <ChatsList conversations={conversationsList} />
                        ) : (
                            <div className={startPageClasses['empty-state-container']}>
                                <div className={startPageClasses['empty-state-text-container']}>
                                    <span>No chats available.</span>
                                    <span>You can start a chat above.</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Outlet />

            <OnboardingModal
                isOpen={enableWhoAmI}
                onClose={goToStartPage}
                bodies={whoAmIBodies}
                images={whoAmIImages}
                slideNr={0}
            />
            <OnboardingModal
                isOpen={isDialogOpen}
                onClose={closeDialog}
                bodies={onboardingBodies}
                images={onboardingImages}
                slideNr={0}
            />

            {!isDialogOpen && <UpdateDialog cutoffDate="2024-09-01" header="Introducing GPT-4o mini" />}
        </div>
    );
};
