import { FC, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlDetails from '@shoelace-style/shoelace/dist/react/details';
import SlInput from '@shoelace-style/shoelace/dist/react/input';
import SlTextarea from '@shoelace-style/shoelace/dist/react/textarea';

import { useGoPage } from '../../../libs/hooks/useRouterNavigate';
import { useAppDispatch } from '../../../redux/app/hooks';
import { setNavigation } from '../../../redux/features/navigation/navigationSlice';
import { PopUpDialog } from '../../popups/PopUpDialog';
import { PopUpDialogNA } from '../../popups/PopUpDialogNoAction';
import { creativityOptions, GPTServiceId } from '../../utils/GPTModels';
import { Constants } from '../../../Constants';
import { ViewStates } from '../../views/ViewStates';
import { IEditAssistantParams } from '../../../libs/services/AssistantService';
import { useChat } from '../../../libs/hooks';
import InternalIcon from '../../global/InternalIcon';

import buttonClasses from '../../global/buttons.module.scss';
import editclasses from './EditTab.module.scss';
import { IAssistant } from '../../../libs/models/Assistants';

const gptModelOptions = [
    {
        value: 'gpt4oMini',
        text: 'GPT-4o mini - Fast, efficient, cost effective.',
        name: 'GPT-4o mini',
    },
    {
        value: 'gpt4o',
        text: 'GPT-4o - Smart, powerful, accurate.',
        name: 'GPT-4o',
    },
];

interface EditTabProps {
    assistant: IAssistant;
    handleEdit: (params: IEditAssistantParams) => void;
    isLoading: boolean;
}

const EditTab: FC<EditTabProps> = ({ assistant, handleEdit, isLoading }) => {
    const dispatch = useAppDispatch();
    const { goStartPage } = useGoPage();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isPromptLoading, setIsPromptLoading] = useState(false);
    const [isNameLoading, setIsNameLoading] = useState(false);
    const pageBodyRef = useRef<HTMLDivElement | null>(null);

    const { getWorkDescription } = useChat('AssistantDescriptionTab');

    const [workDescription, setWorkDescription] = useState(assistant.systemDescription);
    const [description, setDescription] = useState(assistant.description);
    const [creativityLevel, setCreativityLevel] = useState(assistant.responseTemperature);
    const [modelName, setModelName] = useState<GPTServiceId>(assistant.serviceId);
    const [assistantName, setName] = useState(assistant.title);

    const handleEditAssistantApproved = () => {
        handleEdit({
            title: assistantName,
            systemDescription: workDescription,
            description,
            responseTemperature: creativityLevel,
            id: assistant.id,
            active: assistant.active,
            pinned: assistant.pinned,
            serviceId: modelName,
        });
    };

    // Handle assistant prompt
    const onChange = (event: CustomEvent) => {
        const target = event.target as HTMLInputElement;
        setWorkDescription(target.value);
    };
    // Handle assistant name
    const onNameChange = (event: CustomEvent) => {
        const target = event.target as HTMLInputElement;
        setName(target.value);
    };

    // Handle creativity selection
    const onCreativitySelect = (cardName: string) => {
        if (cardName === 'precise') {
            setCreativityLevel(0.1);
        } else if (cardName === 'balanced') {
            setCreativityLevel(0.5);
        } else {
            setCreativityLevel(0.9);
        }
    };

    // ---- Handle model selection
    // Scroll to bottom when opening dropdown
    const handleScrollToBottom = () => {
        if (pageBodyRef.current) {
            pageBodyRef.current.scrollTo({
                top: pageBodyRef.current.scrollHeight,
                behavior: 'smooth', // Smooth scrolling
            });
        }
    };

    function handleSelect(value: string) {
        setModelName(value as GPTServiceId);
    }

    const handleCancel = () => {
        dispatch(setNavigation(ViewStates.Exploring));
        goStartPage();
    };

    // Handle prompt regeneration
    const onPromptGenerate = () => {
        setIsPromptLoading(true);
        const prompt = `I am an expert in something, but I have amnesia. My role as an expert is very important. My job is to answer any questions people have, by offering my expertise. I don't know the details of my expertise, I only have a query to build on. When I give you the query, I want you to describe my role to me as an expert. Keep it short and to the point, and write it in one paragraph without line breaks. Again, I have woken up from a coma, and I have amnesia. How would you describe my role to me, so that I can remember again? Do this based on the following query:
            ${workDescription}`;
        getWorkDescription(prompt)
            .then((response) => {
                setWorkDescription(response);
            })
            .catch((error) => {
                console.error('Error generating work description', error);
            })
            .finally(() => {
                setIsPromptLoading(false);
            });
    };

    // Handle name regeneration
    const onNameGenerate = () => {
        setIsNameLoading(true);
        const prompt = `Give a very brief, yet descriptive, title for a chat bot with the following system prompt:\n\n ${workDescription}\n\n Give it without any quotation marks and keep it max 30 characters long. Try not to mention that it is a chatbot.`;
        getWorkDescription(prompt)
            .then((response) => {
                setName(response);
            })
            .catch((error) => {
                console.error('Error generating name', error);
            })
            .finally(() => {
                setIsNameLoading(false);
            });
    };

    const PopUpController: FC = () => {
        if (assistantName.trim() == '' && workDescription.trim() == '') {
            return (
                <PopUpDialogNA
                    header="Ooops. Something is missing."
                    content="Your assistant needs a prompt and a name."
                    canHide={false}
                    onAction={handleEditAssistantApproved}
                >
                    <SlButton className={buttonClasses['primary-large']} style={{ width: '100%' }}>
                        Save changes
                    </SlButton>
                </PopUpDialogNA>
            );
        } else if (assistantName.trim() == '') {
            return (
                <PopUpDialogNA
                    header="Ooops. Something is missing."
                    content="Your assistant needs a name."
                    canHide={false}
                    onAction={handleEditAssistantApproved}
                >
                    <SlButton className={buttonClasses['primary-large']} style={{ width: '100%' }}>
                        Save changes
                    </SlButton>
                </PopUpDialogNA>
            );
        } else if (workDescription.trim() == '') {
            return (
                <PopUpDialogNA
                    header="Ooops. Something is missing."
                    content="Your assistant needs a prompt."
                    canHide={false}
                    onAction={handleEditAssistantApproved}
                >
                    <SlButton className={buttonClasses['primary-large']} style={{ width: '100%' }}>
                        Save changes
                    </SlButton>
                </PopUpDialogNA>
            );
        } else {
            return (
                <PopUpDialog
                    header="New changes: New chat"
                    content="Changes only affect new chats going forward and won't impact previous chats. Start a new chat to see the new changes."
                    canHide={true}
                    onAction={handleEditAssistantApproved}
                    buttonText="OK"
                >
                    <SlButton disabled={isLoading} className={buttonClasses['primary-large']} style={{ width: '100%' }}>
                        Save changes
                    </SlButton>
                </PopUpDialog>
            );
        }
    };

    return (
        <div className={editclasses['page-wrapper']}>
            <div className={editclasses['header-wrapper']}>
                <h2>Edit your assistant</h2>
                <div className={editclasses['button-wrapper-top']}>
                    <PopUpDialog
                        header={'Unsaved changes'}
                        content={'By exiting assistant creation, all progress will be lost.'}
                        buttonText={'Exit'}
                        onAction={handleCancel}
                        canHide={false}
                    >
                        <SlButton className={buttonClasses['secondary-large']}>Cancel</SlButton>
                    </PopUpDialog>
                    <PopUpController />
                </div>
            </div>
            <div className={editclasses['body-wrapper']} ref={pageBodyRef}>
                <div className={editclasses['section-wrapper']}>
                    {/* ---- Prompt ---- */}
                    <h4>Prompt</h4>
                    <SlTextarea
                        value={workDescription}
                        onSlInput={onChange}
                        placeholder="Enter prompt here"
                        className={editclasses['prompt-textarea']}
                    />
                    <SlButton
                        disabled={workDescription.length !== 0 && !isPromptLoading && !isLoading ? false : true}
                        className={buttonClasses['secondary-large'] + ' ' + editclasses['prompt-button']}
                        onClick={onPromptGenerate}
                    >
                        <Icon icon="lets-icons:lightning-light" width="24px" height="24px" />
                        Regenerate prompt
                    </SlButton>
                </div>

                {/* ---- Name ---- */}
                <div className={editclasses['section-wrapper']}>
                    <h4>Name</h4>

                    <div className={editclasses['name-section-content']}>
                        <SlInput
                            value={assistantName}
                            onSlInput={onNameChange}
                            className={editclasses['name-input']}
                            placeholder="Enter name"
                            maxlength={30}
                        />

                        <SlButton
                            disabled={assistantName.length !== 0 && !isNameLoading && !isLoading ? false : true}
                            className={buttonClasses['secondary-large']}
                            onClick={onNameGenerate}
                        >
                            <Icon icon="lets-icons:lightning-light" width="24px" height="24px" />
                            Regenerate name
                        </SlButton>
                    </div>
                </div>

                <div className={editclasses['section-wrapper']}>
                    <h4>Description</h4>
                    <SlTextarea
                        className={editclasses['share-assistant-description']}
                        // label="Description"
                        help-text="Max 90 symbols"
                        maxlength={90}
                        rows={2}
                        resize="none"
                        value={description}
                        onSlInput={(e) => {
                            setDescription((e.target as HTMLInputElement).value);
                        }}
                    />
                </div>

                {/* ---- Creativity ---- */}
                <div className={editclasses['section-wrapper']}>
                    <h4>Creativity</h4>
                    <div className={editclasses['creativity-section']}>
                        {creativityOptions.map(({ temp, value, title, description }) => (
                            <div
                                key={temp}
                                className={
                                    creativityLevel === temp
                                        ? editclasses['creativity-card-selected']
                                        : editclasses['creativity-card']
                                }
                                onClick={() => {
                                    onCreativitySelect(value);
                                }}
                            >
                                <div className={editclasses['creativity-card-content']}>
                                    <b>{title}</b>
                                    <span>{description}</span>
                                </div>
                                <InternalIcon
                                    icon="check-icon"
                                    style={{
                                        display: creativityLevel === temp ? 'block' : 'none',
                                    }}
                                    className={editclasses['check-icon']}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                {/* ---- Model ---- */}
                <div className={editclasses['section-wrapper']}>
                    <h4>Model</h4>
                    <SlDetails
                        className={editclasses['model-dropdown']}
                        summary={
                            gptModelOptions.find((o) => o.value === modelName)?.name ?? Constants.DEFAULT_MODEL_NAME
                        }
                        open={isDialogOpen}
                        onSlShow={handleScrollToBottom}
                    >
                        {gptModelOptions.map(({ value, text }) => (
                            <SlButton
                                key={`modelKey${value}`}
                                onClick={() => {
                                    setIsDialogOpen(false);
                                    handleSelect(value);
                                }}
                                className={
                                    String(value) === modelName
                                        ? editclasses['model-select-button-selected']
                                        : editclasses['model-select-button']
                                }
                                variant="text"
                            >
                                {text}
                            </SlButton>
                        ))}
                    </SlDetails>
                </div>
                <div className={editclasses['button-wrapper-bottom']}>
                    <PopUpDialog
                        header={'Unsaved changes'}
                        content={'By exiting assistant creation, all progress will be lost.'}
                        buttonText={'Exit'}
                        onAction={handleCancel}
                        canHide={false}
                    >
                        <SlButton className={buttonClasses['secondary-large']} style={{ width: '100%' }}>
                            Cancel
                        </SlButton>
                    </PopUpDialog>
                    <PopUpController />
                </div>
            </div>
        </div>
    );
};

export default EditTab;
