import React, { useRef } from 'react';
import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlDropdown from '@shoelace-style/shoelace/dist/react/dropdown';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

import { useAssistantByIdSelector } from '../../redux/app/hooks';
import { getIsShared } from '../assistant-hub/helper';
import InternalIcon from '../global/InternalIcon';

import tooltipStyles from '../global/tooltip.module.scss';
import assistantlistStyles from './AssistantListItem.module.scss';
import AssistantActions from '../assistant-hub/AssistantActions';
import Preferences from '../../redux/features/app/Preferences';
export type TCreateNewChat = (
    name: string,
    systemDescription?: string,
    serviceId?: string,
    responseTemperature?: number,
    isAssistant?: boolean,
    assistantId?: string,
) => void;

interface IAssistantListItemProps {
    assistantId: string;
    handleNewChat: TCreateNewChat;
    disabled?: boolean;
}

const AssistantListItem: React.FC<IAssistantListItemProps> = ({ assistantId, handleNewChat, disabled }) => {
    const dropdownTimer = useRef<NodeJS.Timeout | null>(null);
    const assistant = useAssistantByIdSelector(assistantId);

    const [isPoppingOver, setIsPoppingOver] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [preventCloseOnMouseLeave, setPreventCloseOnMouseLeave] = React.useState(false);

    const handleDropdownMouseEnter = () => {
        dropdownTimer.current && clearTimeout(dropdownTimer.current);
        setIsPoppingOver(true);
    };

    const closePopOver = () => {
        setIsPoppingOver(false);
        setIsOpen(false);
    };

    const handleDropdownMouseLeave = () => {
        if (preventCloseOnMouseLeave) return;
        dropdownTimer.current = setTimeout(closePopOver, 300);
    };

    const handleClick = () => {
        if (!isPoppingOver) {
            handleNewChat(
                assistant.title,
                assistant.systemDescription,
                assistant.serviceId,
                assistant.responseTemperature,
                true,
                assistantId,
            );
        }
    };

    const isPinned = Preferences.getInstance().isPinned(assistantId);

    return (
        <div className={assistantlistStyles['assistant-item']}>
            <SlButton
                className={assistantlistStyles['start-chat-button']}
                onClick={handleClick}
                disabled={disabled}
                variant="text"
            >
                <div slot="prefix" style={{ display: 'flex' }}>
                    <InternalIcon icon="assistant-bot" width={24} height={24} style={{}} />
                    {getIsShared(assistant) && (
                        <SlIcon name="share" className={assistantlistStyles['share-icon']}></SlIcon>
                    )}
                </div>
                <span>{assistant.title}</span>
                {isPinned && <Icon icon="lets-icons:pin-light" width={18} height={18} />}
            </SlButton>
            <SlTooltip
                content={'Edit ' + assistant.title}
                placement="right"
                className={tooltipStyles['sapience-tooltip']}
            >
                <SlDropdown
                    onMouseEnter={handleDropdownMouseEnter}
                    onMouseLeave={handleDropdownMouseLeave}
                    onSlShow={() => {
                        setIsOpen(true);
                    }}
                    placement="bottom-end"
                    open={isOpen}
                >
                    <SlButton slot="trigger" variant="text" className={assistantlistStyles['meatball-button']}>
                        <Icon icon="lets-icons:meatballs-menu" width={24} height={24} />
                    </SlButton>
                    <div className={assistantlistStyles['assistant-menu']}>
                        <AssistantActions
                            assistantId={assistantId}
                            isSidebar
                            closePopOver={closePopOver}
                            preventCloseOnMouseLeave={() => {
                                setPreventCloseOnMouseLeave(true);
                            }}
                        />
                    </div>
                </SlDropdown>
            </SlTooltip>
        </div>
    );
};

export default AssistantListItem;
