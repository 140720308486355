import { useMsal } from '@azure/msal-react';

import { GPTServiceId } from '../../components/utils/GPTModels';
import { IAssistant } from '../../libs/models/Assistants';
import { useAppDispatch, useAssistantsStatusSelector } from '../../redux/app/hooks';
import {
    addAssistant,
    deleteAssistant,
    editAssistant,
    setAssistants,
    setAssistantsError,
    setAssistantsStatus,
} from '../../redux/features/assistants/assistantsSlice';
import { EStatus } from '../../redux/features/conversations/ConversationsState';
import { AuthHelper } from '../auth/AuthHelper';
import { AssistantService, IEditAssistantParams } from '../services/AssistantService';
import Preferences from '../../redux/features/app/Preferences';
import { addAlert } from '../../redux/features/app/appSlice';
import { AlertType } from '../models/AlertType';

export const useAssistants = (name = '') => {
    if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_SHOW_LOGS === 'true') {
        console.log(name);
    }
    const { instance, inProgress } = useMsal();
    const dispatch = useAppDispatch();
    const assistantsStatus = useAssistantsStatusSelector();
    const assistantService = AssistantService.getInstance();

    const createAssistantAsync = async (
        title: string,
        systemDescription: string,
        responseTemperature: number,
        serviceId: GPTServiceId,
    ) => {
        try {
            return await assistantService
                .createAssistantAsync(
                    title,
                    systemDescription,
                    responseTemperature,
                    serviceId,
                    await AuthHelper.getSKaaSAccessToken(instance, inProgress),
                )
                .then((assistant) => {
                    dispatch(addAssistant(assistant));
                    Preferences.getInstance().toggleAssistantActiveness(assistant.id);
                    return assistant;
                });
        } catch (err) {
            throw err;
        }
    };

    const editAssistantAsync = async (params: IEditAssistantParams) => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        const assistant = await assistantService.editAssistantAsync({ ...params, accessToken });
        dispatch(editAssistant(assistant));
        return assistant;
    };

    const getAllAssistantsAsync = async () => {
        if (assistantsStatus === EStatus.Loading) return null;
        dispatch(setAssistantsStatus(EStatus.Loading));
        const preferencesInstance = Preferences.getInstance();
        try {
            const [privateAssistants, globalAssistants] = await Promise.all([
                assistantService.getprivateAssistantsAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress)),
                assistantService.getGlobalAssistantsAsync(await AuthHelper.getSKaaSAccessToken(instance, inProgress)),
            ]);
            const assistantsObject: Record<string, IAssistant> = {};
            const assistantsArray = privateAssistants.concat(globalAssistants);
            for (const assistant of assistantsArray) {
                assistantsObject[assistant.id] = assistant;
            }
            const { activeAssistants } = preferencesInstance.get();
            if (privateAssistants.length && !activeAssistants.length) {
                privateAssistants.forEach((a) => {
                    preferencesInstance.toggleAssistantActiveness(a.id);
                });
            }
            preferencesInstance.excludeDeletedAssistants(assistantsArray.map((a) => a.id));
            dispatch(setAssistants(assistantsObject));
            dispatch(setAssistantsStatus(EStatus.Succeeded));
            dispatch(setAssistantsError(null));
            return assistantsObject;
        } catch (error) {
            dispatch(setAssistantsStatus(EStatus.Failed));
        }
        return null;
    };

    const deleteAssistantAsync = async (id: string) => {
        try {
            await assistantService.deleteAssistantAsync(id, await AuthHelper.getSKaaSAccessToken(instance, inProgress));
            dispatch(deleteAssistant(id));
        } catch (error) {
            console.error('Error occured while trying to delete assistant with id: ' + id);
        }
    };

    const shareAssistantAsync = async (assistantId: string, description: string) => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const sharedAssistant = await assistantService.shareAssistantAsync(accessToken, assistantId, description);
            dispatch(editAssistant(sharedAssistant));
            dispatch(
                addAlert({
                    message: 'Your assistant has been successfully shared! It is now available for everyone to use.',
                    type: AlertType.Info,
                    id: `assistant shared: ${assistantId}`,
                    isToast: true,
                }),
            );
        } catch (error) {
            console.log(error);
        }
    };

    const unShareAssistantAsync = async (assistantId: string) => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const unSharedAssistant = await assistantService.unShareAssistantAsync(accessToken, assistantId);
            dispatch(editAssistant(unSharedAssistant));
        } catch (error) {
            console.log(error);
        }
    };

    return {
        createAssistantAsync,
        getAllAssistantsAsync,
        deleteAssistantAsync,
        editAssistantAsync,
        shareAssistantAsync,
        unShareAssistantAsync,
    };
};
