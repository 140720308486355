import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlTooltip from '@shoelace-style/shoelace/dist/react/tooltip';

import { useGoPage } from '../../libs/hooks/useRouterNavigate';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import Preferences from '../../redux/features/app/Preferences';

import tooltipClasses from '../global/tooltip.module.scss';
import classes from './SidebarSwitch.module.scss';

export const SideBarSwitch = () => {
    const { showSideBar } = useAppSelector((state: RootState) => state.app.preferences);
    const { isStartPageRoute } = useGoPage();
    const showDarkButton = isStartPageRoute && !showSideBar;
    const icon = `lets-icons:arrow-${showSideBar ? 'left' : 'right'}-stop-light`;

    const toggleSideBar = () => {
        Preferences.getInstance().toggleShowSidebar();
    };

    return (
        // Align the container such that the vertical line of the icon lines up with the end of the sidebar
        // We need to use a container here to ensure the tooltip is positioned correctly
        <div className={`${classes['button-container']} ${showSideBar ? classes['sidebar-visible'] : ''}`}>
            <SlTooltip
                content={showSideBar ? 'Close sidebar' : 'Open sidebar'}
                placement="right"
                className={tooltipClasses['sapience-tooltip']}
            >
                <SlButton
                    size="small"
                    className={showDarkButton ? classes['show-sidebar-button-dark'] : classes['show-sidebar-button']}
                    onClick={toggleSideBar}
                >
                    <Icon icon={icon} width="18px" height="18px" />
                </SlButton>
            </SlTooltip>
        </div>
    );
};

export default SideBarSwitch;
