import { FC } from 'react';
import { Icon } from '@iconify/react';
import SlButton from '@shoelace-style/shoelace/dist/react/button';
import SlIcon from '@shoelace-style/shoelace/dist/react/icon';

import { useChat } from '../../../libs/hooks';
import InternalIcon from '../../global/InternalIcon';
import { usePinnedAssistantsSelector } from '../../../redux/app/hooks';

import assistantStyles from './AssistantsList.module.scss';
import { getIsShared } from '../../assistant-hub/helper';

export const AssistantsList: FC = () => {
    const { createChat } = useChat();
    const pinnedAssistants = usePinnedAssistantsSelector();

    return (
        <div className={assistantStyles.container}>
            {pinnedAssistants.map((assistant, i) => (
                <SlButton
                    onClick={() => {
                        createChat(
                            assistant.title,
                            assistant.systemDescription,
                            assistant.serviceId,
                            assistant.responseTemperature,
                            true,
                            '',
                            assistant.id,
                        ).catch(console.error);
                    }}
                    className={assistantStyles['pinned-assistant']}
                    key={i}
                >
                    {/* Setting the icon to 22px is equivalent to a 24px lets-icon (lets icons have padding) */}
                    <div className={assistantStyles['icon-and-title']}>
                        <InternalIcon icon="assistant-bot" width={22} height={22} style={{ paddingLeft: '2px' }} />
                        {getIsShared(assistant) && (
                            <SlIcon name="share" className={assistantStyles['additional-icon']}></SlIcon>
                        )}
                        {assistant.title}
                    </div>
                    <Icon icon="lets-icons:pin-light" width={'24px'} height={'24px'} />
                </SlButton>
            ))}
        </div>
    );
};
